<template>
<div class="overlay">
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Overlay',
  components: {
  },
  props: {
  },
  setup () {
    return {
    };
  },
});
</script>
